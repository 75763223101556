import "../Styles/index.scss";

import AOS from "aos";
AOS.init();

import { Fancybox } from "@fancyapps/ui";
const domParser = new DOMParser();
Fancybox.bind(".is-zoomable", {
	groupAttr: "data-gallery",
	caption: (fancybox, slide) => {
		return domParser.parseFromString(slide.caption ?? "", "text/html").documentElement.textContent ?? "";
	},
});

import { initializeMaps } from "@wagich/bluc-map";
initializeMaps();

for (let element of document.querySelectorAll<HTMLElement>(".navbar-burger")) {
	element.addEventListener("click", () => {
		const target = element.dataset.target;
		if (target == null) {
			return;
		}
		const targetElement = document.getElementById(target);

		element.classList.toggle('is-active');
		targetElement?.classList.toggle('is-active');
	});
}
